
import { Options, Vue } from 'vue-class-component';

import naics from 'naics';

import IconService from '@/icons/Service.vue';
import { NAICS } from '@/interface/other.interface';

@Options({
  components: {
    IconService,
  },
  props: {
    value: [Number, Object],
    errors: Array,
    icon: Boolean,
  },
  emits: {
    naics: Object,
  },
})
export default class NAICSCode extends Vue {
  declare $props: {
    value: number | string | null;
    errors: string[];
  }

  declare $emits: {
    naics: NAICS;
  }

  public title?: string = '';

  public handleChange(e: string | number): void {
    const title = naics.Industry.from(String(e))?.title;

    this.title = title;

    this.$emit('naics', { code: e, title });
  }
}
